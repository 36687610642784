<template>
    <div class="context-picker" v-if="(remoteValue && options)" :class="{'search-content__empty': searchContentEmpty}">
        
        <div class="context-picker__label" v-if="label">{{ label }}</div>
        <multiselect
            v-model="value"
            label="title"
            :ref="randomId"
            track-by="id"
            :options="options"
            :option-height="200"
            :placeholder="placeholder"
            :searchable="true"
            :custom-label="customLabel"
            :show-labels="false"
            :showNoOptions="false"
            :multiple="multiple"
            :showNoResults="false"
            :close-on-select="closeOnSelect"
            :hideSelected="true"
            @open="initObserve()"
            @close="contextClosed()"
            @search-change="debounceSearch"
						style="position: static;"
        >
            <template slot-scope="props" slot="tag">
                <div class="option-tag__content option-tag__content-preview">
                    <img class="option__image" :src="props.option.img ? props.option.img : '/img/avatar.png'" v-if="label == 'Пользователи' || 'Users'"/>
                    <span class="option__desc">
                        <span class="option__title">{{ props.option.title }}</span>
                    </span>
                    <button class="option-tag__content--close" @click="deselect(props.option.id)">
                        <Icon class="cross-select" name="crossSelect" size="14" />
                    </button>
                </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
                <img :src="option.img" alt=""  v-if="option.img">
                <span>{{ option.title }}</span>
            </template>
            <template slot-scope="value" slot="option">
                <div class="option-tag__list">
                    {{ value.option.name }}
                </div>
            </template>
            <template slot="option" slot-scope="props">
                <div>
                    <div class="option-tag__list">
                        <img class="option__image" :src="props.option.img ? props.option.img : '/img/avatar.png'" v-if="label=='Пользователи'"/>
                        <div class="option__desc">
                            <span class="option__title">{{ props.option.title }}</span>
                        </div>
                        <template v-if="props.option.specialisations && props.option.specialisations.length === 1">
                            <span class="option-tag__specialisation" v-for="specialisation in props.option.specialisations" :key="specialisation.id">
                                {{ specialisation.name }}
                            </span>
                        </template>
                        <div class="option-tag__specialisation-wrp" v-if="props.option.specialisations && props.option.specialisations.length > 1">
                            <span class="option-tag__specialisation" v-for="specialisation in props.option.specialisations" :key="specialisation.id">
                                {{ specialisation.name }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Icon from '@/components/ui/icon/Icon.vue';
import debounce from 'lodash.debounce';
export default {
    components: { Multiselect, Icon },

    props: {
        label: {
            type: String,
        },
        options: {
            type: Array,
        },
        placeholder: {
            type: String,
            default: '',
        },
        remoteValue: {
            type: Array,
        },
        loading: {
            type: Boolean,
        },
        closeOnSelect: {
            type: Boolean,
            default: false,
        },
        currentUsersPage: {
            type: Number,
        },
        currentRolesPage: {
            type: Number,
        },
        page: {
            type: Number,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            value: '',
            debounceCountries: null,
            firstSearch: false,
            query: '',
            searchContentEmpty: false,
            randomId: '',
            pageLoaded: false,
            intersectionObserver: null,

            // Пример
            // options: [
            //     { title: 'при',  img: 'https://i.pinimg.com/564x/58/30/d8/5830d82b72d80b890c62c5cd66841c2b.jpg', id: 1},
            //     { title: 'value',  img: 'https://i.pinimg.com/564x/a8/07/a9/a807a9743588f8094ab7120272eed636.jpg', id: 2 },
            //     { title: 'ыыы', img: 'https://i.pinimg.com/564x/35/63/d1/3563d109ff26c96dee6aadcac464651e.jpg', id: 3},
            //     { title: 'ссс', img: 'https://i.pinimg.com/564x/45/14/21/45142185595a82282ee93d1f0379725e.jpg', id: 4},
            // ],
        };
    },
    created()
    {
        this.randomId = this.makeid(20);
        this.debounceSearch = debounce(this.asyncFind.bind(this), 400);
    },
    mounted()
    {
        
        this.value = this.remoteValue;
        this.pageLoaded = true;
    },
    methods: {
        asyncFind(query)
        {
            this.firstSearch = true;
            query = query.toLowerCase();
            const payload = 
            {
                page: 1,
                search: query,
            }
            this.$emit('search', payload)
        },
        // Проверяем, если элементов с таким классом больше нет значит элементы закончились
        // и скрываем меню 
        issueHideBlock()
        {
            const contextEls = this.$refs[this.randomId].$el.childNodes[4].childNodes[0];
            if(!contextEls.querySelector('.multiselect__element'))
            {
                this.searchContentEmpty = true;
            }
        },
        initObserve()
        {
            const contextEls = this.$refs[this.randomId].$el.childNodes[4].childNodes[0];
            const endEl = document.createElement('div');
                contextEls.querySelectorAll('.multiselect__option').forEach((el) => 
                {
                    el.addEventListener('click', this.issueHideBlock)
                })
                endEl.classList.add(`context-grid__end_${this.randomId}`);
                endEl.style.height = '2px'
                endEl.style.position = 'relative'
                endEl.style.top = '-20px'
                endEl.style.padding = '1x 0'
                
                contextEls.append(endEl);
                this.intersectionObserver = new IntersectionObserver(entries => {
                        if(!this.loading)
                        {
                            if (entries[0].intersectionRatio <= 0 || !entries[0].isIntersecting) return;
                            const response = 
                            {
                                page: this.page,
                                search: this.query,
                            }
                            this.$emit('paginate', response)
                            contextEls.querySelectorAll('.multiselect__option').forEach((el) => 
                            {
                                el.addEventListener('click', this.issueHideBlock)
                            })
                        }
                    }, {threshold: 0});
                this.intersectionObserver.observe(document.querySelector(`.context-grid__end_${this.randomId}`));
            // если нет элементов скрываем

            if(!contextEls.querySelector('.multiselect__element'))
            {
                this.searchContentEmpty = true;
            }
        },
        makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        customLabel(option) {
            return option.title.toLowerCase();
        },
        deselect(item)
        {
            this.$refs[this.randomId].deactivate()
            this.value = this.value.filter((el) => el.id != item)
            this.$emit('blur',this.value);
        },
        contextClosed()
        {
            if(!this.searchContentEmpty)
            {
                this.intersectionObserver.unobserve(document.querySelector(`.context-grid__end_${this.randomId}`));
                if(this.value?.length || this.value)
                {
                    this.$emit('blur',this.value);
                }
                document.getElementsByClassName(`context-grid__end_${this.randomId}`)[0].remove()

            }
            else
            {
                if(this.value?.length || this.value)
                {
                    this.$emit('blur',this.value);
                }
                this.searchContentEmpty = false;
            }
        },
    },
    watch: 
    {
        options:
        {
            handler(val) {
                if(!val.length)
                {
                    this.searchContentEmpty = true
                }
                else
                {
                    this.searchContentEmpty = false;
                }
            },
            deep: true,
        },
        remoteValue(val)
        {
            this.value = val
        },
        // value(val)
        // {
        //     if(this.pageLoaded && (this.value.length > 0 && val.length > 0))
        //     {
        //         this.$emit('change', val)
        //     }
        // },
    },
};
</script>
<style lang="scss">
.context-picker 
{
    .multiselect__select::before
    {
        display: none;
    }
    margin-bottom: 17px;
    .option-tag__list
    {
        display: flex;
        align-items: center;
        margin-left: 17px;
        .option__desc
        {
            margin-left: 9px;
        }
    }
    .option__title
    {
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        margin-right: 4px;
        color: #D3D3D3;
        margin-left: 6px;

    }
    .option-tag__content-preview
    {
        margin: 5px 0;
        font-size: 14px;
        .option__image-empty
        {
            height: 18px;
            width: 18px;
        }
        .option__image
        {
            height: 18px;
            width: 18px;
            object-fit: cover;
        }
    }
    .option-tag__content
    {
        display: flex;
        align-items: center;
        margin-right: 10px;
        &--close
        {
            display: flex;
            cursor: pointer;
            background: none;
            border: none;
            padding: 0;
            img
            {
                height: 15px;
                width: 15px;
                object-fit: cover;
            }
        }
    }
    .option__image-empty
    {
        height: 32px;
        width: 32px;
    }
    .option__image
    {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        object-fit: cover;
    }
    .context-picker__label {
        margin-bottom: 13px;
        font-family: $font-family;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: rgba(255, 255, 255, 0.8);
    }
    .multiselect__tags 
    {
        padding: 8px 40px 8px 8px;
        .multiselect__tags-wrap
        {
            display: flex;
            flex-wrap: wrap;
        }
        border-radius: 9px !important;
        border: 0.7px solid rgba(255, 255, 255, 0.3);
        background: none;
        .multiselect__input {
            background: none;
            color: #fff;
            margin: 0;
        }
        .multiselect__input::placeholder
        {
            background: none;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            color: rgba(255, 255, 255, 0.5);
        }
        input::placeholder
        {
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            color: rgba(255, 255, 255, 0.5);
        }
    }
    .multiselect__content-wrapper {
				max-width: 500px;
        padding: 6px 0;
        scrollbar-color: $dark-700 transparent;
        scrollbar-width: thin;
        margin-top: 5px;
        &::-webkit-scrollbar {
          position: relative;
          width: 7px;
          background-color: transparent;
          z-index: 3;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $dark-700;
          border: 2px $dark-900 solid;
          border-radius: 5px;
        }
    
        background: #191919;
        border: 0.7px solid rgba(255, 255, 255, 0.3);
        border-radius: 8px;
        .multiselect__option
        {
            padding: 0 12px;
            display: flex;
            align-items: center;
        }
        .multiselect__option--highlight {
            background: #191919;
            color: rgba(255, 255, 255, 0.8);
        }
        .multiselect__option--highlight::after {
            display: none;
        }
    }
    .cross-select
    {
        color: #D3D3D3;
    }
    .option-tag__specialisation-wrp
    {
        display: flex;
        flex-wrap: wrap;
        max-width: 400px;
    }
    .option-tag__specialisation
    {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 300;
        font-size: 12px;
        margin-left: 5px;
    }
}
.search-content__empty
{
    .multiselect__content-wrapper
    {
        display: none;
    }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
